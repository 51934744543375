<template>
    <div :class="{'workflow-builder': true, 'workflow-builder--in-map': inMap, 'workflow-builder--disabled': disabled}">
        <div class="workflow-builder__line" />
        <Draggable :list="states" :group="`states-${id}`" @change="emitInput" class="workflow-builder__editor">
            <component
                :is="state.type"
                v-model="states[index]"
                :types="types"
                :key="state.name ? state.name : index"
                :idx="index"
                :in-map="inMap"
                v-for="(state, index) in states"
                :step-checked="stepChecked"
                :disabled="disabled"
                @update:valid="value => onUpdateValid(state.name, value)"
                @remove="() => remove(index)"
                @input="emitInput"
            />
        </Draggable>
        <div class="workflow-builder__add" @click="() => (inMap ? addTask('Task') : openModal())">
            <img src="@/assets/workflow/plus.svg" /><span>Add </span
            ><span class="workflow-builder__add-strong" @click.stop="() => addTask('Task')">task</span
            ><template v-if="!inMap"
                ><span> or </span>
                <span class="workflow-builder__add-strong" @click.stop="() => addTask('Map')"
                    >group task</span
                ></template
            >
        </div>
        <AddTaskModal v-model="showAddTaskModal" @addTask="addTask" />
    </div>
</template>

<script>
import Draggable from 'vuedraggable'

import {collectTypes} from '@/shared/collectTypes'
import {processTypes} from '@/shared/processTypes'

export default {
    components: {
        Draggable,
        AddTaskModal: () => import('./AddTaskModal.vue'),
        Map: () => import('./Map.vue'),
        Task: () => import('./Task.vue'),
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        inMap: {
            type: Boolean,
            default: false,
        },
        stepChecked: {
            type: Boolean,
            default: false,
        },
        valid: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            id: this.$randomId(),
            states: [],
            showAddTaskModal: false,
            statesValid: {},
        }
    },
    created() {
        if (this.value && this.value.states) {
            this.init(this.value)
        }
    },
    computed: {
        types() {
            return [...collectTypes, ...processTypes].reduce(
                (acc, curr) => ({
                    ...acc,
                    [`${curr.type} ${curr.subtype}`]: {...curr, img: curr.img ? require(`@/assets/${curr.img}`) : null},
                }),
                {}
            )
        },
        workflow() {
            const states = this.states.reduce((acc, curr, index) => {
                const {
                    name,
                    comment,
                    type,
                    resource,
                    retryAttempts,
                    retryDelay,
                    itemsPath,
                    iterator,
                    maxConcurrency,
                    passValue,
                } = curr

                const newStates = []

                if (passValue && passValue.length) {
                    const previousStateIndex = this.value.states.findIndex(state => state.name === name)
                    const jobBeforePreviousState = this.value.states[previousStateIndex - 1]
                    const previousPassState =
                        jobBeforePreviousState && jobBeforePreviousState.type === 'Pass' ? jobBeforePreviousState : null

                    newStates.push({
                        name: previousPassState ? previousPassState.name : `Pass - ${name}`,
                        comment: '',
                        type: 'Pass',
                        next: name,
                        computed: passValue,
                        result: {},
                    })
                }

                const state = {name, comment, type, end: index === this.states.length - 1 ? true : undefined}
                if (type === 'Task') {
                    if (retryAttempts || retryDelay) {
                        state.retryAttempts = retryAttempts
                        state.retryDelay = retryDelay
                    }
                    newStates.push({...state, resource})
                }
                if (type === 'Map') {
                    newStates.push({...state, itemsPath, iterator, maxConcurrency})
                }

                const previous = index > 0 ? acc[acc.length - 1] : null
                if (previous) {
                    previous.next = newStates[0].name
                }

                return [...acc, ...newStates]
            }, [])

            const schema = {
                startAt: states.length ? states[0].name : '',
                states,
            }

            return schema
        },
        validComp() {
            return this.states.every(state => this.statesValid[state.name])
        },
    },
    methods: {
        init(value) {
            const states = []
            const passStates = []

            value.states.forEach(el => {
                if (el.type === 'Pass') {
                    passStates.push(el)
                } else {
                    states.push(el)
                }
            })

            passStates.forEach(el => {
                const task = states.find(state => state.name === el.next)

                if (task) {
                    task.passValue = el.computed
                }
            })

            this.states = states
        },

        openModal() {
            this.showAddTaskModal = true
        },

        addTask(type) {
            const state = {
                type,
            }

            this.states.push(state)

            this.emitInput()
        },

        remove(index) {
            this.states.splice(index, 1)
            this.emitInput()
        },

        emitInput() {
            this.$emit('input', this.workflow)
            this.emitUpdate()
        },

        emitUpdate() {
            this.$emit('update:valid', this.validComp)
        },

        onUpdateValid(key, value) {
            this.$set(this.statesValid, key, value)
            this.emitUpdate()
        },
    },
}
</script>

<style lang="scss">
.workflow-builder {
    $self: &;
    position: relative;

    &__line {
        position: absolute;
        top: 0;
        left: 25px;
        border-left: 2px solid #dadada;
        height: 100%;
        z-index: 99;
    }

    &__editor {
        position: relative;
        z-index: 105;
    }

    &__add {
        position: relative;
        z-index: 100;

        border-radius: 8px;
        box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
        background-color: white;
        padding: 5px 15px;
        margin: 10px 0;
        height: 58px;
        cursor: pointer;

        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        img {
            margin-right: 10px;
            height: 24px;
            width: 24px;
        }

        > span {
            line-height: 24px;
            padding-bottom: 2px;
        }

        .workflow-builder__add-strong {
            font-weight: 600;
            padding: 0 4px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &--in-map {
        #{ $self }__line {
            height: calc(100% + 15px);
            top: -15px;
        }
    }

    &--disabled {
        cursor: not-allowed;

        #{ $self }__editor {
            .workflow-builder-task,
            .workflow-builder-map {
                background-color: #f8f8f8;
            }
        }

        #{ $self }__add {
            background-color: #f8f8f8;
        }

        > * {
            pointer-events: none;
        }
    }
}
</style>
