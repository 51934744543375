<template>
    <div class="animated fadeIn">
        <wit-tabbed-form
            :formId="formId"
            :steps="steps"
            headerLogo="workflow/workflow.png"
            headerText="Setup Your Workflow"
            @continue="onContinue"
            @reset="resetForm"
            @finish="createWorkflow"
            @input="onFormInput"
            ref="tabbedForm"
            :details="isDetails"
            :dirty="updateDirty"
            :fetched="fetched"
            :update="updateWorkflow"
            :update-redirect="updateRedirect"
        >
            <template v-slot:header-right-btn--after>
                <transition-group name="opacity">
                    <b-btn v-if="isDetails" variant="primary" @click="runAgain" id="run-workflow-btn" key="run">
                        <i class="icon-reload"></i>
                        <span> Run again</span>
                    </b-btn>
                    <b-btn v-if="isDetails" variant="primary" @click="copyWorkflow" id="copy-workflow-btn" key="copy">
                        <i class="fa fa-copy"></i>
                        <span> Copy</span>
                    </b-btn>
                </transition-group>
            </template>

            <template v-slot:step-1-input-schema="{step}">
                <Builder
                    v-if="fetchedAll"
                    v-model="workflowForm.schema"
                    ref="builder"
                    :step-checked="step.checked"
                    :valid.sync="workflowValid"
                />
                <Feedback
                    :state="validateRef('workflowForm.schema', step)"
                    invalid="Your workflow is invalid"
                    valid="Workflow schema is valid"
                ></Feedback>
            </template>

            <template v-slot:step-2-input-create-schedule="{step}" v-if="!isDetails">
                <wit-input-group
                    v-if="scheduleForm.scheduled"
                    input-label="Workflow schedule"
                    v-model="scheduleForm.schedulers"
                    type="text"
                >
                    <b-row>
                        <b-col md="8">
                            <CronGenerator @onScheduleChange="getSchedule"></CronGenerator>
                            <Feedback
                                :state="validateRef('scheduleForm.schedulers', step)"
                                invalid="Schedule is required"
                                valid="Scheduler is valid"
                            ></Feedback>
                        </b-col>
                    </b-row>
                </wit-input-group>
            </template>

            <template v-slot:step-2-input-details-schedule="{step}" v-if="isDetails">
                <div
                    v-if="
                        scheduleForm.scheduled &&
                            currentScheduleForm &&
                            currentScheduleForm.schedulers &&
                            currentScheduleForm.schedulers.length > 0
                    "
                >
                    <b-row>
                        <b-col md="2" cols="0" />
                        <b-col md="8"> <strong>These are the current schedulers:</strong></b-col>
                    </b-row>
                    <br />
                    <ScheduledDetailsRow label="Workflow Schedule" :schedulers="currentScheduleForm.schedulers">
                        <b-col md="2">
                            <b-btn variant="primary" @click="() => (showSchedulerForm = true)"
                                ><i class="fa fa-pencil" /> Change</b-btn
                            >
                        </b-col></ScheduledDetailsRow
                    >
                </div>

                <div v-if="showSchedulerForm">
                    <div
                        v-if="
                            currentScheduleForm &&
                                currentScheduleForm.schedulers &&
                                currentScheduleForm.schedulers.length > 0
                        "
                    >
                        <br />
                        <b-row>
                            <b-col md="2" cols="0" />
                            <b-col md="8"> <strong>These will be the new schedulers:</strong></b-col>
                        </b-row>
                        <br />
                    </div>
                    <wit-input-group
                        v-if="scheduleForm.scheduled"
                        input-label="Workflow schedule"
                        v-model="scheduleForm.schedulers"
                        type="text"
                    >
                        <b-row>
                            <b-col md="8">
                                <CronGenerator @onScheduleChange="getSchedule"></CronGenerator>
                                <Feedback
                                    :state="validateRef('scheduleForm.schedulers', step)"
                                    invalid="Schedule is required"
                                    valid="Scheduler is valid"
                                ></Feedback>
                            </b-col>
                        </b-row>
                    </wit-input-group>
                </div>
            </template>

            <template v-slot:step-2-feedback>
                <b-row>
                    <b-col>
                        <Feedback :state="workflowError.state" :invalid="workflowError.message"></Feedback>
                    </b-col>
                </b-row>
            </template>
        </wit-tabbed-form>

        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import VueNotifications from 'vue-notifications'
import {mapGetters} from 'vuex'

import {validationMixin} from 'vuelidate'
import {required, requiredIf, minLength} from 'vuelidate/lib/validators'

import CronGenerator from '@/components/CronGenerator.vue'
import Feedback from '@/components/Feedback.vue'
import Loading from '@/components/loading.vue'
import ScheduledDetailsRow from '@/components/ScheduledDetailsRow.vue'
import WitTabbedForm from '@/components/WitTabbedForm.vue'
import WitInputGroup from '@/components/Inputs/WitInputGroup.vue'

import Builder from './Builder/Index.vue'

import {formMixin} from '@/mixins/formMixin'
import {filterDirty} from '@/shared/filterDirty.js'
import {fetchAll} from '@/shared/fetchAll.js'

const cloneDeep = require('lodash.clonedeep')

export default {
    components: {
        Builder,
        CronGenerator,
        Feedback,
        Loading,
        ScheduledDetailsRow,
        WitTabbedForm,
        WitInputGroup,
    },
    notifications: {
        couldNotCopy: {
            type: VueNotifications.types.error,
            title: 'Could not copy',
        },
        runAgainError: {
            type: VueNotifications.types.error,
        },
        runAgainSuccess: {
            type: VueNotifications.types.success,
            title: 'Workflow started successfully',
        },
    },
    data() {
        return {
            workflowForm: {
                name: '',
                schema: {},
            },
            currentWorkflowForm: {},
            scheduleForm: {
                schedulers: [
                    {
                        schedule: null,
                        name: 'runWorkflow',
                    },
                ],
                scheduled: true,
                timezone: 'Europe/Warsaw',
            },
            currentScheduleForm: {},
            showSchedulerForm: false,
            workflowError: {
                state: null,
                message: null,
            },
            workflow: null,
            timezoneOptions: process.env.VUE_APP_TIMEZONES.split(','),
            fetchedAll: false,
            updateRedirect: '/workflow?workflowUpdated=true',
            workflowValid: true,
        }
    },
    mixins: [formMixin, validationMixin],
    validations: {
        workflowForm: {
            name: {required},
            schema: {
                required,
                startAt: {required},
                states: {required, minLength: minLength(1)},
                valid: function() {
                    return this.workflowValid
                },
            },
        },
        scheduleForm: {
            schedulers: {
                required: requiredIf(function() {
                    return this.scheduleForm.scheduled
                }),
                $each: {
                    schedule: {
                        required: requiredIf(function() {
                            return this.scheduleForm.scheduled
                        }),
                    },
                },
            },
            scheduled: {},
            timezone: {required},
        },
    },
    computed: {
        ...mapGetters({
            pipelines: 'pipeline/pipelines',
            activeProject: 'project/active',
        }),
        isDetails() {
            return Boolean(this.$route.params.id)
        },
        isCopy() {
            return Boolean(this.$route.query.cid)
        },
        formId() {
            return this.isDetails ? 'workflow-update-form' : 'workflow-create-form'
        },
        cannotContinue() {
            return this.isDetails || this.isCopy ? true : false
        },
        updateDirty() {
            const workflowFormDirty = filterDirty(
                JSON.parse(JSON.stringify(this.workflowForm)),
                this.currentWorkflowForm
            )
            const scheduleFormDirty = filterDirty(this.scheduleForm, this.currentScheduleForm)
            return Object.keys({...workflowFormDirty, ...scheduleFormDirty}).length > 0
        },
        steps() {
            return [
                {
                    name: 'Workflow Settings',
                    invalid: this.$v.workflowForm.$invalid,
                    inputs: [
                        {
                            name: 'name',
                            id: 'workflow-name',
                            value: this.$v.workflowForm.name.$model,
                            model: this.$v.workflowForm.name,
                            valid: 'Workflow name is valid',
                            invalid: 'This field is required',
                            type: 'text',
                            inputLabel: 'Enter Workflow name',
                            placeholder: 'Name',
                        },
                        {
                            name: 'schema',
                        },
                    ],
                },
                {
                    name: 'Schedule Settings',
                    invalid: this.$v.$invalid,
                    inputs: [
                        {
                            name: 'schedule-workflow-radio',
                            id: 'schedule-workflow-radio',
                            value: this.$v.scheduleForm.scheduled.$model,
                            model: this.$v.scheduleForm.scheduled,
                            type: 'checkbox',
                            inputLabel: 'Schedule Workflow',
                            onInput: this.onScheduledChange,
                        },
                        {
                            name: 'timezone',
                            id: 'workflow-timezone',
                            value: this.$v.scheduleForm.timezone.$model,
                            model: this.$v.scheduleForm.timezone,
                            valid: 'Timezone is valid',
                            invalid: 'Timezone is required',
                            type: 'select',
                            inputLabel: 'Timezone',
                            placeholder: 'Select timezone',
                            options: this.timezoneOptions,
                        },
                        {name: 'create-schedule', vIf: !this.isDetails},
                        {name: 'details-schedule', vIf: this.isDetails},
                    ],
                },
            ]
        },
        isAutomatic() {
            return this.isDetails && this.workflow && this.workflow.autoCreated
        },
    },
    async created() {
        if (this.isDetails) {
            this.$store.commit('loading/PROCESSING', `Preparing the Workflow Visualization...`)
            await this.fetchWorkflow()
            await fetchAll(this.$store, this.activeProject.id)
            this.$store.commit('loading/PROCESSED')
            this.fetchedAll = true
            this.fetched = true
        } else {
            this.$store.commit('loading/PROCESSING', `Preparing the Workflow Visualization...`)
            await fetchAll(this.$store, this.activeProject.id)

            if (this.isCopy) {
                await this.copyFrom(this.$route.query.cid)
            }

            this.$nextTick(() => (this.fetchedAll = true))

            this.$store.commit('loading/PROCESSED')

            const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
            const formSessionId = formSessionMap[this.$route.path].id

            this.$gtm.trackEvent({
                event: 'create_resource_start',
                action: 'create',
                value: formSessionId,
            })
        }
    },
    methods: {
        async fetchWorkflow() {
            const response = await this.axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/workflow/${this.$route.params.id}`
            )
            this.workflow = response.data.data

            this.workflowForm = {
                name: this.workflow.name,
                schema: this.workflow.schema,
            }
            this.scheduleForm = {
                scheduled: this.workflow.schedulers.length > 0,
                schedulers: cloneDeep(this.workflow.schedulers),
                timezone: this.workflow.timezone,
            }
            this.currentWorkflowForm = cloneDeep(this.workflowForm)
            this.currentScheduleForm = cloneDeep(this.scheduleForm)

            this.showSchedulerForm = false
        },

        getSchedule(value) {
            this.scheduleForm.schedulers[0].schedule = value
        },

        async updateWorkflow() {
            if (!this.$v.$invalid) {
                const workflowFormDirty = filterDirty(this.workflowForm, this.currentWorkflowForm)
                const scheduleFormDirty = filterDirty(this.scheduleForm, this.currentScheduleForm)
                if (Object.keys({...workflowFormDirty, ...scheduleFormDirty}).length > 0) {
                    let data = {
                        ...workflowFormDirty,
                        projectId: this.activeProject.id,
                    }
                    if (Object.keys(scheduleFormDirty).length > 0) {
                        data.timezone = scheduleFormDirty.timezone ? scheduleFormDirty.timezone : undefined
                        data.schedulers = this.scheduleForm.scheduled ? this.scheduleForm.schedulers : []
                    }

                    this.$store.commit('loading/PROCESSING', `Updating the Workflow...`)
                    try {
                        await this.axios.put(
                            `${process.env.VUE_APP_NODE_API_HOST}/workflow/${this.$route.params.id}`,
                            data
                        )

                        this.workflowError = {}
                        this.currentWorkflowForm = cloneDeep(this.workflowForm)
                        this.currentScheduleForm = cloneDeep(this.scheduleForm)
                        this.showSchedulerForm = false
                        this.$v.$reset()

                        this.$store.commit('loading/PROCESSED')
                    } catch (exception) {
                        this.$store.commit('loading/PROCESSED')
                        this.workflowError.state = false
                        this.workflowError.message = exception.response.data.data
                        this.$errorHandler.report(exception, 'Could not update workflow')
                    }
                }
            }
        },

        createWorkflow(step) {
            step.check()
            this.$forceUpdate()
            if (!this.$v.$invalid) {
                const data = {
                    ...this.workflowForm,
                    projectId: this.activeProject.id,
                    timezone: this.scheduleForm.timezone,
                    schedulers: this.scheduleForm.scheduled ? this.scheduleForm.schedulers : undefined,
                }
                this.$store.commit('loading/PROCESSING', `Creating Your Workflow...`)
                this.axios
                    .post(`${process.env.VUE_APP_NODE_API_HOST}/workflow`, data)
                    .then(response => {
                        const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
                        const formSessionId = formSessionMap[this.$route.path].id

                        this.$gtm.trackEvent({
                            event: 'create_resource_finish',
                            action: 'finish',
                            value: formSessionId,
                        })

                        this.$store.commit('loading/PROCESSED')

                        this.$projectRouter.push('/workflow?workflowCreated=true')
                    })
                    .catch(exception => {
                        this.$store.commit('loading/PROCESSED')
                        this.workflowError.state = false
                        this.workflowError.message = exception.response.data.data
                        this.$errorHandler.report(exception, 'Could not create workflow')
                    })
            }
        },

        onScheduledChange() {
            if (this.isDetails) {
                if (this.scheduleForm.scheduled) {
                    this.showSchedulerForm = true
                    this.scheduleForm.schedulers = [
                        {
                            schedule: null,
                            name: 'runWorkflow',
                        },
                    ]
                } else {
                    this.showSchedulerForm = false
                }
            }
        },

        copyWorkflow() {
            this.$projectRouter.push(`/workflow/create?cid=${this.$route.params.id}`)
        },

        async copyFrom(id) {
            try {
                const response = await this.axios.get(`${process.env.VUE_APP_NODE_API_HOST}/workflow/${id}`)
                this.workflow = response.data.data

                this.workflowForm = {
                    name: `Copy of ${this.workflow.name}`,
                    schema: this.workflow.schema,
                }
                this.scheduleForm = {
                    scheduled: this.workflow.schedulers.length > 0,
                    schedulers: cloneDeep(this.workflow.schedulers),
                    timezone: this.workflow.timezone,
                }
            } catch (e) {
                this.couldNotCopy()
                this.$errorHandler.report(e, 'Could not copy workflow')
            }
        },

        async runAgain() {
            this.$store.commit('loading/PROCESSING', `Starting...`)
            try {
                await this.axios.post(`${process.env.VUE_APP_NODE_API_HOST}/workflow/run`, {
                    workflowId: this.$route.params.id,
                })
                this.runAgainSuccess()
                this.$store.commit('loading/PROCESSED')
            } catch (exception) {
                this.$store.commit('loading/PROCESSED')
                const message = exception.response.data.data
                this.runAgainError({title: `Workflow could not start. ${message ? `\nError message: ${message}` : ''}`})
            }
        },

        onContinue() {
            this.continueForm()
            this.$refs.builder.init(this.workflowForm.schema)
        },
    },
}
</script>

<style lang="scss">
.switch {
    margin-top: -3px;
}

#copy-workflow-btn {
    margin-left: 5px;
}

#run-workflow-btn {
    margin-left: 5px;
}
</style>
