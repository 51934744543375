<template>
    <wit-input-group :input-label="label" v-model="friendlyCron" type="text">
        <b-row>
            <b-col md="8">
                <b-form-input type="text" id="schedule" v-model="friendlyCron" readonly></b-form-input>
            </b-col>
            <slot></slot>
        </b-row>
    </wit-input-group>
</template>

<script>
import WitInputGroup from '@/components/Inputs/WitInputGroup.vue'
import cronstrue from 'cronstrue'

export default {
    props: {
        label: {
            type: String,
            default: 'Schedule',
        },
        schedulers: {
            type: Array,
            required: true,
        },
        locale: {
            type: String,
            default: 'en',
        },
    },
    components: {
        WitInputGroup,
    },
    computed: {
        friendlyCron: {
            get() {
                if (this.schedulers) {
                    const cronStrings = this.schedulers.map((scheduler, index) => {
                        const cronString = cronstrue.toString(scheduler.schedule, {locale: this.locale})
                        if (index > 0 && index === this.schedulers.length - 1)
                            return `, and ${cronString.charAt(0).toLowerCase() + cronString.substring(1)}`
                        else if (index > 0) return `, ${cronString.charAt(0).toLowerCase() + cronString.substring(1)}`
                        else return cronString
                    })
                    return cronStrings.join('')
                }
            },
        },
    },
}
</script>

<style></style>
